import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { urlConstants } from "../../../../common/constants/urlConstants";

export class ApiService<T> {
  public loading: boolean;
  private data: T | null;
  private error: string | null;

  constructor() {
    this.loading = false;
    this.data = null;
    this.error = null;
  }

  public async callApi({ ...config }: AxiosRequestConfig): Promise<T | null> {
    this.loading = true;
    this.data = null;
    this.error = null;

    try {
      const response: AxiosResponse<T> = await axios({
        ...config,
        url: `${urlConstants.BASE_URL}${config.url}`,
      });
      this.data = response.data;
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.message);
        this.error = error.message;
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        }
      } else {
        console.error("Unexpected error:", error);
        this.error = "Unexpected error occurred";
      }
      return null;
    } finally {
      this.loading = false;
    }
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public getData(): T | null {
    return this.data;
  }

  public getError(): string | null {
    return this.error;
  }
}
