import React, { useRef, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import InputField from "../../../common/components/formFields/inputField/inputField";
import { read, utils } from "xlsx";
import SampleFileButton from "./SampleFileButton/SampleFileButton";
import DownloadResults from "./DownloadResults/DownloadResults";
import ResultsTable from "./ResultsTable/ResultsTable";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import styles from "./AlgoliaSearch.module.scss";
import { constants } from "../../../common/constants/constants";
import { ApiService } from "./customApiService/customApiService";

export type IAlgoliaSearchResults = {
  id: string;
  objectId: string;
  query: string;
  title: string;
  supplier: string;
  price: string;
  userDefinedPackage: string;
  packaging: string;
  category: string;
  subCategory: string;
  brandName: string;
  targetPrice: string;
};

export type AlgoliaSearchResponse = {
  status: string;
  data: {
    [key: string]: {
      id: string;
      title: string;
      supplier: string;
      price: number[];
      packaging: string[];
      category: string;
      subCategory: string;
      brandName: string;
    }[];
  };
};

const AlgoliaSearchForm = () => {
  const [queries, setQueries] = useState(1);
  const [inputValues, setInputValues] = useState<string[]>([""]);
  const [reportData, setReportData] = useState<IAlgoliaSearchResults[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: string }>(
    {}
  );
  const [showOffline, setShowOffline] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [loading, setLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "Select",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
          <Checkbox
            key={params.row.id as string}
            checked={
              !!selectedRows[params.row.query] &&
              selectedRows[params.row.query] === params.row.objectId
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRows((prevSelectedRows) => ({
                  ...prevSelectedRows,
                  [params.row.query]: params.row.objectId as string,
                }));
              } else {
                setSelectedRows((prevSelectedRows) => {
                  const newSelectedRows = { ...prevSelectedRows };
                  delete newSelectedRows[params.row.query];
                  return newSelectedRows;
                });
              }
            }}
          />
        </div>
      ),
    },
    {
      field: "query",
      headerName: "Query",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "brandName",
      headerName: "Brand Name",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "subCategory",
      headerName: "Sub Category",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "packaging",
      headerName: "Packaging",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "targetPrice",
      headerName: "Target Price",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const handleInputChange = (index: number, value: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const fetchAlgoliaData = new ApiService<AlgoliaSearchResponse>();

  const fetchAlogoliaResults = async (
    values: string[],
    targetPrices: { [key: string]: string } = {},
    limits: string[] = [],
    unitMeasure: string[] = [],
    distribution: string[] = [],
    packaging: { [key: string]: string } = {}
  ) => {
    setLoading(true);
    let queriesWithLimits: { query: string; limit: string }[] = [];
    if (limits.length) {
      queriesWithLimits = values.map((query, index) => ({
        query,
        limit: limits[index],
        unitMeasure: unitMeasure[index],
        distribution: distribution[index],
      }));
    }

    const data = await fetchAlgoliaData.callApi({
      method: "post",
      url: "store/algolia/get-algolia-search-result",
      data: {
        queries: limits.length
          ? queriesWithLimits
          : values.filter(Boolean).map((query) => ({ query })),
        offline: showOffline,
        deactivated: showDeactivated,
      },
    });
    const alterdata: IAlgoliaSearchResults[] = Object.entries(data?.data || {})
      .map(([query, items]) => {
        return items.map((item) => ({
          id: crypto.getRandomValues(new Uint32Array(1))[0].toString(),
          objectId: item.id,
          query,
          title: item.title ?? "N/A",
          supplier: item.supplier ?? "N/A",
          price: item?.price?.map((i) => `AED ${i}`)?.join(", ") ?? "N/A",
          userDefinedPackage: packaging[query] ?? "N/A",
          packaging: item.packaging?.join(", "),
          category: item.category ?? "N/A",
          subCategory: item.subCategory ?? "N/A",
          brandName: item.brandName ?? "N/A",
          targetPrice: targetPrices[query] ?? "N/A",
        }));
      })
      .flat();
    setReportData(alterdata);
    setSelectedRows({});
    setLoading(false);
  };

  const handleSubmit = async (form: React.FormEvent) => {
    form.preventDefault();
    if (inputValues.filter(Boolean).length) {
      await fetchAlogoliaResults(inputValues);
    }
  };

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const readXlsxFile = async (file: File): Promise<Array<string>[]> => {
    const data = await file.arrayBuffer();
    const workbook = read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    return utils.sheet_to_json(worksheet, { header: 1 });
  };

  const getValuesByHeader = (rows: Array<string>[], header: string) => {
    const headerIndex = rows[0]
      .map((v) => v.toLowerCase())
      .indexOf(header.toLowerCase());
    if (headerIndex === -1) {
      console.error(`Header "${header}" not found`);
      setToastData({
        status: "Error",
        message: `Header "${header}" not found`,
      });
      return [];
    }
    return rows.slice(1).map((row) => row[headerIndex]);
  };

  const onUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    let productDetails: Array<string> = [];
    let targetPrices: Array<string> = [];
    let limits: Array<string> = [];
    let packaging: Array<string> = [];
    let unitMeasure: Array<string> = [];
    let distribution: Array<string> = [];
    const file = e.target.files?.[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel" ||
        fileType === "text/csv"
      ) {
        const rows = await readXlsxFile(file);
        targetPrices = getValuesByHeader(rows, "target price");
        productDetails = getValuesByHeader(rows, "Product Details");
        limits = getValuesByHeader(rows, "Limit");
        packaging = getValuesByHeader(rows, "Packaging");
        unitMeasure = getValuesByHeader(rows, "Unit Measure");
        distribution = getValuesByHeader(rows, "Distribution");
      } else {
        console.error("Unsupported file type");
      }
    }
    const productToPrice = productDetails.reduce(
      (acc: { [key: string]: string }, product, index) => {
        acc[product] = targetPrices[index];
        return acc;
      },
      {}
    );
    const productToPackage = productDetails.reduce(
      (acc: { [key: string]: string }, product, index) => {
        acc[product] = packaging[index];
        return acc;
      },
      {}
    );
    setInputValues([""]);
    setQueries(1);
    await fetchAlogoliaResults(
      productDetails,
      productToPrice,
      limits,
      unitMeasure,
      distribution,
      productToPackage
    );
    e.target.value = "";
  };

  return (
    <>
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
          handleClose={() => setToastData(constants.defaultToastData)}
        />
      )}
      <Grid container direction="column">
        <h1 className={styles.heading}>Algolia Search Form</h1>
        <form onSubmit={handleSubmit} style={{ paddingLeft: "40px" }}>
          <Grid container flexDirection="column" gap="8px">
            <Grid
              item
              container
              flexDirection="row"
              style={{
                width: "fit-content",
              }}
              gap="10px"
            >
              {Array.from({ length: queries }, (_, i) => (
                <Grid key={i}>
                  <Grid item container className={styles.transparentInput}>
                    <InputField
                      placeholder="Search"
                      id={`Search${i}`}
                      maxWidth="100%"
                      onChange={(e: any) => handleInputChange(i, e)}
                      value={inputValues[i] || ""}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid container flexDirection="row" gap="6px">
                <Grid item>
                  <button
                    type="button"
                    className={styles.commonBtn}
                    onClick={() => {
                      if (inputValues.every(Boolean)) {
                        setQueries(queries + 1);
                        setInputValues([...inputValues, ""]);
                      }
                    }}
                  >
                    Add Search
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    className={styles.commonBtn}
                    onClick={() => {
                      if (queries > 1) {
                        setQueries(queries - 1);
                        setInputValues(inputValues.slice(0, -1));
                      }
                    }}
                  >
                    Remove Search
                  </button>
                </Grid>
                <Grid item>
                  <Tooltip title="Upload a CSV or xlsx file">
                    <button
                      className={styles.commonBtn}
                      type="button"
                      onClick={handleFileUploadClick}
                      disabled={loading}
                    >
                      Upload File
                    </button>
                  </Tooltip>
                  <input
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    type="file"
                    className={styles.commonBtn}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onUpload}
                  />
                </Grid>
                <Grid item>
                  <SampleFileButton />
                </Grid>
                <Grid item>
                  <DownloadResults
                    columns={columns}
                    reportData={reportData}
                    selectedRows={selectedRows}
                    disabled={!reportData.length || loading}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControlLabel
                label="show deactivated"
                control={
                  <Checkbox
                    checked={showDeactivated}
                    onChange={(e) => setShowDeactivated(e.target.checked)}
                  />
                }
              />
              <FormControlLabel
                label="show offline"
                control={
                  <Checkbox
                    checked={showOffline}
                    onChange={(e) => setShowOffline(e.target.checked)}
                  />
                }
              />
            </Grid>
            <Grid
              item
              style={{
                width: "fit-content",
              }}
            >
              <button
                className={styles.commonBtn}
                type="submit"
                disabled={loading}
              >
                Submit
              </button>
            </Grid>
          </Grid>
        </form>
        <ResultsTable
          columns={columns}
          reportData={reportData}
          loading={loading}
        />
      </Grid>
    </>
  );
};

export default AlgoliaSearchForm;
