export const routeConfig = Object.freeze({
  default: "/",
  dashboard: "/buyer/dashboard",
  marketPlace: "/buyer/marketplace",
  buyerProductDetails: "/buyer/product-details/:productId",
  buyerBusinessSetupForm: "/buyer/setup-business",
  buyerOrdersListing: "/buyer/orders-listing",
  buyerLoadingScreen: "/buyer/loading-screen",
  buyerInvoices: "/buyer/manage-invoices",
  buyerInvoiceDetails: "/buyer/invoices-details/:invoiceId",
  catalogue: "/catalogue",
  pricing: "/pricing",
  addIndividual: "/Add-Individual",
  login: "/login",
  signin: "/signin",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  signup: "/signup",
  accountVerification: "/account-verification",
  resetLink: "/reset-link",
  resetCompleted: "/reset-completed",
  produtLinsting: "/product-listing",
  adminSupplierSetupForm: "/admin/users/add-supplier",
  adminBuyerSetupForm: "/admin/users/add-buyer",
  adminDashboard: "/admin/dashboard",
  supplierDashboard: "/supplier/dashboard",
  supplierOnboardinDashboard: "/supplier/onboarding-dashboard",
  manageOrders: "/supplier/manage-orders/:orderId",
  productListingCatalogue: "/supplier/product-listinge-catalogue",
  orderListingCatalogue: "/supplier/order-listing",
  manageSupplierCatalogue: "/supplier/manage-catalogue",
  modifyBulkProduct: "/supplier/manage-catalogue/modify-bulk-product",
  adminModifyBulkProduct: "/admin/manage-catalogue/modify-bulk-product",
  downloadManageCatalogue: "/supplier/download-manage-catalogue",
  adminDownloadManageCatalogue: "/admin/download-manage-catalogue",
  addIndividualProduct: "/supplier/catalogue/add-individual-product",
  addBulkProduct: "/supplier/add-bulk-product",
  adminAddBulkProduct: "/admin/add-bulk-product",
  addBulkSupplierProductFromAdmin: "/admin/add-bulk-product/:supplierId",
  manageCatalogue: "/manage-catalogue",
  buyerApprovalForm: "/admin/buyer-approval-form/:businessId",
  supplierApprovalForm: "/admin/supplier-approval-form/:businessId",
  buyerBusinessApprovalPage: "/admin/buyer-approval",
  supplierBusinessApprovalPage: "/admin/supplier-approval",
  adminAddBuyer: "/admin/users",
  adminAddSupplier: "/admin/add-supplier",
  adminEditSupplier: "/admin/users/add-supplier/:businessId/:supplierEdit",
  adminProductsApprovalSupplier: "/admin-products-approval-supplier",
  businessSetupForm: "/supplier/setup-business",
  algoliaSearch: "/admin/algolia-search",
  productDetailsPage: "/supplier/product-details",
  adminProductApproval: "/admin/products-approval",
  adminProductApprovalForm:
    "/admin/products-approval/products-approval-form/:productId",
  inVoices: "/supplier/invoices",
  invoiceDetails: "/supplier/invoices-details/:invoiceId",
  invoiceDetailsAdmin:
    "/admin/manage-admin-invoices/invoices-details/:invoiceId",
  loadingScreen: "/supplier/loading-screen",
  supplierProductEditForm: "/supplier/catalogue/product-update/:productId",
  adminManageCatalogue: "/admin/manage-catalogue",
  adminProductAddCards: "/admin/manage-add-product",
  uploadSupplierProductsFromAdmin:
    "/admin/upload-supplier-products/:supplierId",
  adminManageOrders: "/admin/manage-admin-orders",
  adminOrderDetails: "/admin/manage-admin-orders/order-details/:orderId",
  confirmContactNo: "/confirm-contact-number",
  myProfile: "/my-profile",
  adminLevelForm: "/admin/users/add-admin",
  updateAdminLevelForm: "/admin/users/update-admin-form/:adminId",
  adminManageCatalogueForm: "/admin/manage-catalogue/edit-product/:productId",
  adminAddProductForm: "/admin/manage-catalogue/add-product",
  adminManageInvoices: "/admin/manage-admin-invoices",
  productDetailsPageAdmin:
    "/admin/products-approval/product-details/:productId",
  productDetailsPageAdminFromCatalogue:
    "/admin/manage-catalogue/product-details/:productId",
  productDetailsPageSupplier: "/supplier/catalogue/product-details/:productId",
  linkIndividualProduct: "/admin/link-product/:supplierId/:warehouseId",
  linkProduct: "/admin/link-product",
  resendVerification: "/generate-email-verification",
  supplierReport: "/supplier/report",
  referAFriend: "/supplier/refer-a-friend",
  supplierFullyLoadedDashboard: "/supplier/fully-loaded-dashboard",
  specialPricing: "/supplier/special-pricing",
  buyerSpecialPricing: "/buyer/special-pricing",
  adminReport: "/admin/reports",
  notifications: "/supplier/notifications",
  adminSpecialPricing: "/admin/special-pricing",
  AdminSpecialPricingRequest: "/admin/special-pricing/request",
  supplierSpecialPricingRequest: "/supplier/special-pricing/request",
  myCart: "/buyer/my-cart",
  placeOrder: "/buyer/place-order",
  subCategoryItemsListPage:
    "/buyer/marketplace/:categoryId/subcategory-items-list",
  ProductsListAndFiltersPage: "/buyer/marketplace/products-list",
  getNewCartID: "/store/business-setup/",
  buyerPreferences: "/buyer/preferences",
  supplierPreferences: "/supplier/preferences",
  offlineSuppliers: "/admin/offline-suppliers",
  updateOfflineSupplier: "/admin/offline-suppliers/:customerId",
  buyerAddNewSupplier: "/buyer/add-new-supplier",
  editBulkProduct: "/supplier/manage-catalogue/edit-bulk-product",
  adminEditBulkProduct: "/admin/manage-catalogue/edit-bulk-product",
  shareProductDetails: "/catalogue/share-product/:productId",
  privacyPolicy: "/privacy-policy",
  adminCustomersList: "/admin/customers/list",
  createFinanceRequest: "/admin/create-finance-request",
});
